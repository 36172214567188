import { axiosClient } from "@definitions/api";
import { AxiosResponse } from "axios";
import { ListResponse } from "@interfaces/types";
import {
	ICreateJobRequestData,
	IJobRequest,
	IUpdateJobRequestData,
} from "@interfaces/api/job-requests.types";
import { IOption } from "@components/form/form-async-select/form-async-select";
import { removeFromStorage } from "@utils/local-storage";
import {
	LSAuthData,
	LSAuthToken,
	LSTemporaryToken,
} from "@definitions/local-storage.keys";
import Router from "next/router";

export const createJobRequest = async (
	data: ICreateJobRequestData,
): Promise<IJobRequest> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<IJobRequest> = await axios.post(
			`JobRequests`,
			data,
		);
		return resp.data;
	} catch (err: any) {
		if (err.status === 401) {
			removeFromStorage(LSAuthData);
			removeFromStorage(LSTemporaryToken);
			removeFromStorage(LSAuthToken);
		}
		throw err.response;
	}
};

export const getJobRequests = async (params?: {
	userId?: number;
	jobOfferId?: number;
	companyId?: number;
}): Promise<ListResponse<IJobRequest>> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<IJobRequest>> = await axios.get(
			`JobRequests/List`,
			{ params },
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const getJobRequestStatuses = async (): Promise<
	ListResponse<IOption>
> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<ListResponse<IOption>> = await axios.get(
			`JobRequests/Statuses`,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};

export const updateJobRequest = async (
	id: number,
	data: IUpdateJobRequestData,
): Promise<IJobRequest> => {
	try {
		const axios = axiosClient();
		const resp: AxiosResponse<IJobRequest> = await axios.patch(
			`JobRequests/${id}`,
			data,
		);
		return resp.data;
	} catch (err: any) {
		throw err.response;
	}
};
