import React, { FC, useEffect, useState } from "react";
import {
	Box,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Stack,
	Tooltip,
	useDisclosure,
} from "@chakra-ui/react";
import { Button } from "@components";
import { BsPencilSquare } from "react-icons/bs";
import {
	addTag,
	getSuggestedTags,
	getTagGroup,
	removeTag,
} from "@api/tags.api";
import TagAsyncSelect, {
	ITagOption,
} from "@components/tag-async-select/tag-async-select";
import { ITag, TaggingObjectType } from "@interfaces/api/tags.types";
import FDC from "@components/layout/fetch-dependable-content/FDC";
import { IUser } from "@interfaces/api/auth.types";
import SectionHeader from "@components/section-header/section-header";
import { ITagProps, Tag } from "@components/tags/tag";
import { TagGroup } from "@components/tags/tag-group";
import { withTagGroupPopover } from "@components/tags/withTagGroupPopover";
import { useQuery } from "@tanstack/react-query";
import {
	jobOfferSuggestedTagsQK,
	tagGroupsQK,
} from "@definitions/react-query.keys";

interface TagsEditorProps {
	data?: { tags: ITag[]; [key: string]: any };
	onAdd?: (userData: IUser) => void | Promise<any>;
	onRemove?: (userData: IUser) => void | Promise<any>;
	objectType?: TaggingObjectType;
	objectId?: number | string;
	editable?: boolean;
	opened?: boolean;
	colorScheme?: string;
	onClose?: () => void;
}

const TagsEditor: FC<TagsEditorProps> = ({
	opened,
	colorScheme,
	data,
	objectType,
	objectId,
	onAdd,
	onRemove,
	editable = true,
	onClose: onCloseProps,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const {
		isOpen: editorOpen,
		onOpen: onEditorOpen,
		onClose: onEditorClose,
	} = useDisclosure();

	useEffect(() => {
		opened && onEditorOpen();
	}, [opened]);

	const {
		data: suggestedTags,
		isLoading,
		refetch,
	} = useQuery({
		queryKey: jobOfferSuggestedTagsQK([{ id: data?.id }]),
		queryFn: () => getSuggestedTags(data?.id),
		enabled: !!data?.id,
		refetchOnWindowFocus: false,
	});

	const onTagAssign = async (tag: ITagOption) => {
		try {
			if (objectType && objectId) {
				const userData = await addTag(tag.value, objectType, objectId);
				onAdd && (await onAdd(userData));
				await refetch();
			}
		} catch (e) {
			console.warn(e);
		}
	};

	const onTagRemove = async (tag: ITagOption) => {
		try {
			if (objectType && objectId) {
				const userData = await removeTag(tag.value, objectType, objectId);
				onRemove && (await onRemove(userData));
				await refetch();
			}
		} catch (e) {
			console.warn(e);
		}
	};

	const openEditor = () => {
		onEditorOpen();
	};

	const closeEditor = () => {
		onEditorClose();
		onCloseProps?.()
	};

	const onModalClose = () => {
		onClose();
	};

	return (
		<>
			<TagGroup tags={data?.tags}>
				{editable && (
					<Tag
						as={Button}
						onClick={openEditor}
						tag={{ label: "", value: "" }}
						size={"md"}
						h={"24px"}
						fontWeight={600}
					>
						<BsPencilSquare />
					</Tag>
				)}
			</TagGroup>

			<Modal isOpen={editorOpen} onClose={closeEditor} size={"lg"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<Box maxW={300}>
							<TagAsyncSelect onSelect={onTagAssign} />
						</Box>
						<ModalCloseButton />
					</ModalHeader>
					<ModalBody>
						<SectionHeader label={"Added tags"} />
						<Box maxH={"150px"} minH={"60px"} overflowY={"auto"} pr={4}>
							<FDC isLoading={isLoading}>
								<TagGroup
									tags={data?.tags || []}
									tagProps={{ onRemove: onTagRemove }}
									TagComponent={(props) =>
										withTagGroupPopover<ITagProps>(
											Tag,
											props,
										)({ tag: props.tag })
									}
								/>
							</FDC>
						</Box>
						<SectionHeader label={"Suggested tags"} />
						<Box maxH={"150px"} minH={"60px"} overflowY={"auto"} pr={4}>
							<FDC isLoading={isLoading}>
								<TagGroup
									tags={suggestedTags?.items || []}
									tagProps={{ colorScheme: "green", onClick: onTagAssign }}
									TagComponent={(props) =>
										withTagGroupPopover<ITagProps>(
											Tag,
											props,
										)({ tag: props.tag })
									}
								/>
							</FDC>
						</Box>
					</ModalBody>
					<ModalFooter>
						<Stack
							direction={{ sm: "column", lg: "row" }}
							justify={"space-between"}
							w={"100%"}
						>
							<Button variant="ghost" onClick={closeEditor}>
								Cancel
							</Button>
						</Stack>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default TagsEditor;
