import React from "react";
import {
	Box,
	Heading,
	HStack,
	Stack,
	useColorModeValue,
	VStack,
} from "@chakra-ui/react";
import { IJobOffer } from "@interfaces/api/job-offers.types";
import FormInputDisplay from "@components/form-elements-display/form-input-display/form-input-display";
import TagsEditor from "@components/views/tags-editor/tags-editor";
import ShareButton from "@components/buttons/share-button/share-button";
import ApplyForJobButton, {
	ApplyForJobButtonProps,
} from "@components/buttons/apply-for-job-button/apply-for-job-button";

interface JobOfferFormProps {
	data?: IJobOffer;
	children?: React.ReactChildren;
	applyButtonProps?: ApplyForJobButtonProps & { canApply: boolean };
	withTitle?: boolean;
}
const JobOfferFormDisplay: React.FC<JobOfferFormProps> = ({
	data,
	applyButtonProps,
	withTitle = true,
}) => {
	const { canApply } = applyButtonProps || {};
	const {
		title,
		description,
		clientDescription,
		primaryResponsibilities,
		benefits,
		profile,
		period,
	} = data || {};

	const SPACING = 4;

	const textColor = useColorModeValue("brand.800", "brand.400");

	return (
		<Box>
			<Stack
				direction={{ base: "column" }}
				spacing={SPACING}
				w={"100%"}
				justify={"start"}
			>
				<Stack
					direction={{ base: "column", lg: "row" }}
					justify={"space-between"}
					align={"center"}
				>
					{withTitle && (
						<VStack spacing={SPACING} align={{ base: "start" }}>
							<HStack>
								<Heading
									size={"xl"}
									textAlign={{ base: "center", lg: "start" }}
									fontWeight={600}
									color={textColor}
								>
									{title}
								</Heading>
								{!!data && (
									<ShareButton
										link={`${window?.location.origin}/?id=${data?.id}`}
									/>
								)}
							</HStack>

							<TagsEditor data={data} editable={false} />
						</VStack>
					)}
					{canApply && <ApplyForJobButton {...applyButtonProps} />}
				</Stack>

				<HStack w={"100%"} border={"1px solid grey"} justify={"space-between"}>
					<VStack spacing={SPACING} align={"start"} justify={"start"} p={4}>
						{!!data?.reference && (
							<Heading size={"md"} fontWeight={500} color={textColor}>
								Reference: {data.reference}
							</Heading>
						)}
						{!!data?.location?.city && (
							<Heading size={"md"} fontWeight={500} color={textColor}>
								Location: {data.location.city}
							</Heading>
						)}
						{!!data?.jobLevelExperience?.label && (
							<Heading size={"md"} fontWeight={500} color={textColor}>
								Level of experience: {data.jobLevelExperience.label}
							</Heading>
						)}
					</VStack>
					<VStack spacing={SPACING} align={"start"} justify={"end"} p={4}>
						{!!data?.reference && (
							<Heading size={"md"} fontWeight={500} color={textColor}></Heading>
						)}
						{!!data?.period && (
							<Heading size={"md"} fontWeight={500} color={textColor}>
								{data?.period?.label}
							</Heading>
						)}
						{!!data?.jobLevelExperience?.label && (
							<Heading size={"md"} fontWeight={500} color={textColor}></Heading>
						)}
					</VStack>
				</HStack>
			</Stack>
			<Stack p={6}>
				{!!description && (
					<Box mb={4}>
						<FormInputDisplay text={description} />
					</Box>
				)}
				{!!clientDescription && (
					<Box mb={4} color={textColor}>
						<Heading size={"md"} fontWeight={600}>
							Client's description
						</Heading>
						<FormInputDisplay text={clientDescription} />
					</Box>
				)}
				{!!primaryResponsibilities && (
					<Box mb={4} color={textColor}>
						<Heading size={"md"} fontWeight={600}>
							Primary responsibilities
						</Heading>
						<FormInputDisplay text={primaryResponsibilities} />
					</Box>
				)}
				{!!benefits && (
					<Box mb={4} color={textColor}>
						<Heading size={"md"} fontWeight={600}>
							Benefits
						</Heading>
						<FormInputDisplay text={benefits} />
					</Box>
				)}
				{!!profile && (
					<Box mb={4} color={textColor}>
						<Heading size={"md"} fontWeight={600}>
							Your profile
						</Heading>
						<FormInputDisplay text={profile} />
					</Box>
				)}
			</Stack>
		</Box>
	);
};

export default JobOfferFormDisplay;
