import React, { FC } from "react";
import { Button } from "@components";
import {
	Box,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalOverlay,
	Stack,
	useDisclosure,
} from "@chakra-ui/react";
import CandidateCreation from "@components/views/candidate-creation/candidate-creation";
import useAuthContext from "@hooks/useAuthContext";
import { MainButton } from "@components/buttons/main-button/main-button";

export interface ApplyForJobButtonProps {
	onAuthorizedApply?: (data?: any & { id: number }) => void;
	isLoading?: boolean;
}

const ApplyForJobButton: FC<ApplyForJobButtonProps> = ({
	onAuthorizedApply = () => null,
	isLoading,
}) => {
	const { authData } = useAuthContext();
	const { isOpen, onOpen, onClose } = useDisclosure();

	const onApplyClick = async (data?: any & { id: number }) => {
		if (data?.id) {
			try {
				await onAuthorizedApply(data);
				onClose();
			} catch (e) {
				console.log(e);
			}
		} else {
			onOpen();
		}
	};

	return (
		<>
			<MainButton
				onClick={() => onApplyClick(authData)}
				fontSize={20}
				py={6}
				px={10}
				isLoading={isLoading}
			>
				Apply
			</MainButton>
			<Modal isOpen={isOpen} onClose={onClose} size={"full"}>
				<ModalOverlay />
				<ModalContent>
					<ModalCloseButton
						borderRadius={"100%"}
						borderWidth={"2px"}
						borderColor={"gray.500"}
						p={4}
					/>
					<ModalBody>
						<Box pt={10}>
							<CandidateCreation
								onCreated={onApplyClick}
								userFormProps={{ fieldsRequired: true }}
							/>
						</Box>
					</ModalBody>
					<ModalFooter>
						<Stack
							direction={{ sm: "column", lg: "row" }}
							justify={"space-between"}
							w={"100%"}
						>
							<Button variant="ghost" onClick={onClose}>
								Cancel
							</Button>
						</Stack>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ApplyForJobButton;
